import styled from 'styled-components';
import { generateGrid, generateCol, generateBreakpoint } from '@layouts/grid';
import { generateVwPercentageHeight } from '@src/helpers';
import colors from '@shared/colors';

export const StyledNewsOverview = styled.div``;

export const StyledNewsItems = styled.div`
    ${generateGrid()}

    padding: 18px 12px;
    padding-bottom: 0;

    ${generateBreakpoint('lg')('padding: 40px 20px')}
`;

export const FeaturedItem = styled.div``;

export const Item = styled.div`
    ${generateCol(12)};
    position: relative;
    margin-bottom: 60px;

    ${generateBreakpoint('md')(generateCol(6))}

    ${generateBreakpoint('lg')(generateCol(4))}

    ${generateBreakpoint('xl')(generateCol(3))}
`;

export const ImageWapper = styled.div`
    ${generateVwPercentageHeight(354, 531, 320)}
    position: relative;
    width: 100%;
    overflow: hidden;
    background-color: ${colors.brownGrey};

    ${generateBreakpoint('md')(`${generateVwPercentageHeight(354, 443, 700)}`)}

    ${generateBreakpoint('lg')(
        `background-color: transparent;
        border: 1px solid ${colors.brownGrey};
        ${generateVwPercentageHeight(354, 443, 1024)}
        `,
    )}

    ${generateBreakpoint('xl')(`${generateVwPercentageHeight(354, 443)}`)}
`;

export const ItemInner = styled.div`
    position: relative;
    width: 100%;
`;

export const TextWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 18px 13px;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    ${generateBreakpoint('lg')(
        `position: relative;
        top: initial;
        left: initial;
        height: auto;
        width: auto;
        padding: 0;
        margin-top: 16px;
        display: block;`,
    )}
`;

export const ItemTitle = styled.h2`
    font-size: 19px;
    min-height: 3em;
    font-weight: bold;
    line-height: 1.4;
    letter-spacing: -0.38px;
    text-transform: uppercase;

    span {
        line-height: 1;
        padding: 0 4px;
        padding-top: 2px;
        box-decoration-break: clone;
        background-color: black;

        width: 100%;
        background-image: linear-gradient(
            transparent calc(100% - 2px),
            #000000 2px
        );
        background-repeat: no-repeat;
        transition: background-size 700ms ease-out;
        background-size: 0% 100%;
    }

    &:hover span {
        background-size: 100% 100%;
    }

    ${generateBreakpoint('lg')(
        `line-height: 1.26;
        min-height: 2.5em;

        span {
            color: black;
            padding: 0;
            line-height: inherit;
            background-color: initial;
        }`,
    )}
`;

export const ItemDate = styled.div`
    font-size: 12px;
    line-height: 1.25;
    letter-spacing: 0.24px;
    text-transform: uppercase;

    span {
        line-height: 1;
        padding: 0 4px;
        padding-top: 2px;
        box-decoration-break: clone;
        background-color: black;
    }

    ${generateBreakpoint('lg')(
        `margin-top: 8px;
        font-size: 14px;
        line-height: 1.21;
        letter-spacing: 0.28px;

        span {
            color: black;
            padding: 0;
            line-height: inherit;
            background-color: initial;
        }`,
    )}
`;

export const ItemInfo = styled.div`
    margin-top: 18px;

    font-family: 'SuisseIntl';
    font-size: 12px;
    line-height: 1.42;
    letter-spacing: 0.24px;
    color: #000000;

    ${generateBreakpoint('lg')(
        `font-size: 14px;
        line-height: 1.43;
        letter-spacing: 0.28px;`,
    )}
`;
