import React, { Fragment } from 'react';
import { graphql, Link } from 'gatsby';
import { get } from 'lodash';
import compareDesc from 'date-fns/compareDesc';
import parseISO from 'date-fns/parseISO';
import { formatDate } from '@src/helpers';
import LatestRecordings from '@components/latest-recordings';

import {
    StyledNewsOverview,
    StyledNewsItems,
    Item,
    ItemInner,
    ItemTitle,
    TextWrapper,
    ItemDate,
    ImageWapper,
    ItemInfo,
} from './index.styled';
import { Banner, MobileBanner } from '../../components/news-slices/banner';

const combineNewsAndEvents = ({ events = [], news = [], featured_item }) => {
    const results = {
        featured: {},
        items: [],
    };

    // add type for render option
    results.items = [
        ...events.map(e => ({
            ...e.node,
            type: 'event',
            date: e.node.date_start,
        })),
        ...news.map(n => ({ ...n.node, type: 'article' })),
    ];

    // check if featured item is available
    if (featured_item) {
        results.featured = featured_item;
        results.items = results.items.filter(
            i => i._meta.uid !== featured_item._meta.uid,
        );
    }

    // sort by date/date_start DESC
    results.items = results.items.sort((a, b) =>
        compareDesc(parseISO(a.date), parseISO(b.date)),
    );

    if (results.items.length && !Object.keys(results.featured).length) {
        results.featured = results.items.shift();
    }

    return results;
};

const NewsOverviewPage = ({ data, pageContext }) => {
    if (!data || !pageContext) {
        return null;
    }
    const { latestRecordings, uid } = pageContext;
    const {
        prismic: {
            allEvents: { edges: events },
            allNewss: { edges: news },
            newsoverview: { featured_item },
        },
    } = data;

    const { featured, items } = combineNewsAndEvents({
        events,
        news,
        featured_item,
    });

    if (!Object.keys(featured).length) {
        return null;
    }

    return (
        <Fragment>
            <StyledNewsOverview>
                <Link to={`/${uid}/${featured._meta.uid}`}>
                    <Banner
                        image={featured.top_banner_image}
                        imageSharp={featured.top_banner_imageSharp}
                        mobileImage={featured.mobile_banner_image}
                        mobileImageSharp={featured.mobile_banner_imageSharp}
                        mobileAlignment={featured.mobileAlignment}
                        title={get(featured, 'title[0].text', '')}
                        isH1
                    />
                </Link>
                <StyledNewsItems>
                    {items && items.length
                        ? items.map((item, i) => (
                              <Item key={`item_${i}`}>
                                  <ItemInner>
                                      <ImageWapper>
                                          <Link
                                              to={`/${uid}/${item._meta.uid}`}
                                          >
                                              <MobileBanner
                                                  mobileImage={
                                                      item.mobile_banner_image
                                                  }
                                                  mobileImageSharp={
                                                      item.mobile_banner_imageSharp
                                                  }
                                                  mobileAlignment={
                                                      item.mobileAlignment
                                                  }
                                              />
                                          </Link>
                                      </ImageWapper>
                                      <TextWrapper>
                                          <Link
                                              to={`/${uid}/${item._meta.uid}`}
                                          >
                                              <ItemTitle>
                                                  <span>
                                                      {get(
                                                          item,
                                                          'title[0].text',
                                                          '',
                                                      )}
                                                  </span>
                                              </ItemTitle>
                                          </Link>
                                          <ItemDate>
                                              {item.date ? (
                                                  <span>
                                                      {formatDate(item.date)}
                                                  </span>
                                              ) : (
                                                  <span>no date</span>
                                              )}
                                          </ItemDate>
                                      </TextWrapper>
                                  </ItemInner>
                                  <ItemInfo>{item.seoDescription}</ItemInfo>
                              </Item>
                          ))
                        : null}
                </StyledNewsItems>
            </StyledNewsOverview>
            <LatestRecordings inverted items={latestRecordings} />
        </Fragment>
    );
};

export const query = graphql`
    query NewsOverviewQuery($uid: String!) {
        prismic {
            allEvents(sortBy: meta_firstPublicationDate_DESC) {
                edges {
                    node {
                        _meta {
                            uid
                        }
                        title
                        seoDescription: seo_description
                        poster_image
                        poster_imageSharp {
                            childImageSharp {
                                fluid {
                                    srcSet
                                    src
                                }
                            }
                        }
                        top_banner_image
                        top_banner_imageSharp {
                            childImageSharp {
                                fluid {
                                    src
                                    srcSet
                                }
                            }
                        }
                        mobile_banner_alignment
                        mobile_banner_image
                        mobile_banner_imageSharp {
                            childImageSharp {
                                fluid {
                                    src
                                    srcSet
                                }
                            }
                        }
                        date_start
                        info
                    }
                }
            }
            allNewss(sortBy: meta_firstPublicationDate_DESC) {
                edges {
                    node {
                        _meta {
                            uid
                        }
                        title
                        date
                        seoDescription: seo_description
                        top_banner_image
                        top_banner_imageSharp {
                            childImageSharp {
                                fluid {
                                    src
                                    srcSet
                                }
                            }
                        }
                        mobile_banner_alignment
                        mobile_banner_image
                        mobile_banner_imageSharp {
                            childImageSharp {
                                fluid {
                                    src
                                    srcSet
                                }
                            }
                        }
                        intro_headline
                    }
                }
            }
            newsoverview(lang: "en-au", uid: $uid) {
                featured_item {
                    ... on PRISMIC_Event {
                        title
                        date_start
                        seoDescription: seo_description
                        poster_image
                        poster_imageSharp {
                            childImageSharp {
                                fluid {
                                    src
                                    srcSet
                                }
                            }
                        }
                        _meta {
                            uid
                        }
                    }
                    ... on PRISMIC_News {
                        title
                        seoDescription: seo_description
                        top_banner_image
                        _meta {
                            uid
                        }
                        mobile_banner_image
                        mobile_banner_alignment
                        mobile_banner_imageSharp {
                            childImageSharp {
                                fluid {
                                    srcSet
                                    srcSetWebp
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default NewsOverviewPage;
